import * as React from 'react';
import type { SVGProps } from 'react';

export const IdentificationCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#000"
      d="M15.625 9.499a.625.625 0 0 1-.625.625h-3.125a.625.625 0 1 1 0-1.25H15a.625.625 0 0 1 .625.625M15 11.374h-3.125a.625.625 0 0 0 0 1.25H15a.625.625 0 1 0 0-1.25m3.125-6.25v11.25a1.25 1.25 0 0 1-1.25 1.25H3.125a1.25 1.25 0 0 1-1.25-1.25V5.124a1.25 1.25 0 0 1 1.25-1.25h13.75a1.25 1.25 0 0 1 1.25 1.25m-1.25 11.25V5.124H3.125v11.25zm-6.27-2.656a.625.625 0 0 1-1.211.312c-.206-.802-1.02-1.406-1.895-1.406-.874 0-1.688.604-1.894 1.406a.625.625 0 0 1-1.211-.312 3.1 3.1 0 0 1 1.343-1.824 2.5 2.5 0 1 1 3.525 0 3.1 3.1 0 0 1 1.343 1.823M7.5 11.374a1.25 1.25 0 1 0 0-2.501 1.25 1.25 0 0 0 0 2.5"
    />
  </svg>
);
