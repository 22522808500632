import * as React from 'react';

export const HandHeart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 256 256"
    {...props}
  >
    <path d="M230.33 141.06a24.34 24.34 0 00-18.61-4.77C230.5 117.33 240 98.48 240 80c0-26.47-21.29-48-47.46-48A47.58 47.58 0 00156 48.75 47.58 47.58 0 00119.46 32C93.29 32 72 53.53 72 80c0 11 3.24 21.69 10.06 33a31.87 31.87 0 00-14.75 8.4L44.69 144H16a16 16 0 00-16 16v40a16 16 0 0016 16h104a7.93 7.93 0 001.94-.24l64-16a6.94 6.94 0 001.19-.4L226 182.82l.44-.2a24.6 24.6 0 003.93-41.56zM119.46 48a31.15 31.15 0 0129.14 19 8 8 0 0014.8 0 31.15 31.15 0 0129.14-19C209.59 48 224 62.65 224 80c0 19.51-15.79 41.58-45.66 63.9l-11.09 2.55A28 28 0 00140 112h-39.32C92.05 100.36 88 90.12 88 80c0-17.35 14.41-32 31.46-32zM16 160h24v40H16zm203.43 8.21l-38 16.18L119 200H56v-44.69l22.63-22.62A15.86 15.86 0 0189.94 128H140a12 12 0 010 24h-28a8 8 0 000 16h32a8.32 8.32 0 001.79-.2l67-15.41.31-.08a8.6 8.6 0 016.3 15.9z"></path>
  </svg>
);
