import * as React from 'react';
import type { SVGProps } from 'react';

export const Visibility = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 13.125q1.48 0 2.51-1.031 1.032-1.032 1.032-2.51 0-1.48-1.031-2.511Q11.479 6.04 10 6.042q-1.48 0-2.51 1.03-1.032 1.032-1.032 2.511t1.032 2.51T10 13.126m0-1.208a2.25 2.25 0 0 1-1.656-.677 2.25 2.25 0 0 1-.677-1.657q0-.979.677-1.656A2.25 2.25 0 0 1 10 7.25q.98 0 1.656.677.677.677.677 1.656 0 .98-.677 1.657a2.25 2.25 0 0 1-1.656.677m0 3.916q-3.042 0-5.5-1.729a10.53 10.53 0 0 1-3.667-4.52A10.53 10.53 0 0 1 4.5 5.061Q6.958 3.333 10 3.333t5.5 1.73a10.53 10.53 0 0 1 3.667 4.52 10.53 10.53 0 0 1-3.667 4.521q-2.458 1.73-5.5 1.73m0-1.25q2.52 0 4.636-1.364a8.86 8.86 0 0 0 3.218-3.636 8.86 8.86 0 0 0-3.218-3.635A8.4 8.4 0 0 0 10 4.583q-2.52 0-4.635 1.365a9 9 0 0 0-3.24 3.635 9 9 0 0 0 3.24 3.636A8.38 8.38 0 0 0 10 14.583"
      fill="#353535"
    />
  </svg>
);
