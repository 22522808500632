import * as React from 'react';
import type { SVGProps } from 'react';

export const VisibilityOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13.104 11.27-.916-.916q.54-1.479-.563-2.458-1.104-.98-2.396-.5l-.916-.917q.354-.23.791-.333T10 6.042q1.48 0 2.51 1.03 1.032 1.032 1.032 2.511 0 .459-.115.907a2.8 2.8 0 0 1-.323.78m2.688 2.688-.834-.833a9.8 9.8 0 0 0 1.782-1.677q.76-.927 1.114-1.865-1.041-2.312-3.125-3.656t-4.52-1.344q-.876 0-1.792.167t-1.438.396l-.958-.98q.73-.333 1.864-.583t2.22-.25q2.978 0 5.447 1.698 2.468 1.699 3.615 4.552a10.3 10.3 0 0 1-1.396 2.438q-.855 1.104-1.98 1.937M17 18.667l-3.5-3.438a8 8 0 0 1-1.646.448q-.916.156-1.854.156-3.042 0-5.52-1.698Q2 12.438.832 9.583A9.5 9.5 0 0 1 1.99 7.47 12 12 0 0 1 3.792 5.5L1.167 2.875l.875-.896 15.77 15.771zM4.646 6.375q-.77.562-1.49 1.48-.719.916-1.031 1.728a8.34 8.34 0 0 0 3.198 3.657q2.136 1.343 4.844 1.343.687 0 1.354-.083.667-.084 1-.25l-1.334-1.333a2.2 2.2 0 0 1-.562.156 4 4 0 0 1-.625.052q-1.459 0-2.5-1.02-1.041-1.022-1.042-2.522 0-.312.052-.625a2.6 2.6 0 0 1 .157-.562z"
      fill="#353535"
    />
  </svg>
);
